<template>
<div>
  
  <DashboardHeader></DashboardHeader>

  <v-row class="pb-5 mx-sm-0">
    <v-col cols="12">

      <v-divider class="my-3"/>
      
      <v-icon>mdi-cart-arrow-down</v-icon> <b>Réservations</b>
      <v-btn @click="loadResas()" elevation="0" class="ml-2"
              dark fab x-small>
        <v-icon small>mdi-refresh</v-icon>
      </v-btn>

      <v-divider class="my-3"/>
      
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="blue">mdi-circle</v-icon> Réservé
      </v-chip>
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="green">mdi-circle</v-icon> Payé
      </v-chip>
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="teal">mdi-circle</v-icon> Frais supp en attente
      </v-chip>
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="amber">mdi-circle</v-icon> Frais supp payé
      </v-chip>
      
    </v-col>
    <template v-for="(resa, r) in reservations">
      <v-col cols="12" sm="6" md="4" :key="r" class="">
        <CardResa :resa="resa"></CardResa>
      </v-col>
    </template>
  </v-row>

</div>
</template>

<style></style>

<script>
import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import DashboardHeader from '@/components/admin/DashboardHeader.vue'
import CardResa from '@/components/admin/card/CardResa.vue'

export default {
  name: 'admin-dashboard',
  components: { DashboardHeader, CardResa },
  data: () => ({
    reservations: [],
  }),
  async mounted(){
    this.loadResas()

    this.$root.$off('reloadReservations').$on('reloadReservations', () => { 
      this.loadResas()
    })

    this.$root.$off('addToChartSold')
    this.$root.$off('rebootChartSold')
    this.$root.$off('rerenderChartSold')
  },
  methods: {  
    async loadResas(){
      //let loaded = this.reservations.length > 0
      let res = await axios.post('/admin/get-reservations', {
                      })
      if(res.data.error == false){
        this.reservations = res.data.entities
        // if(loaded) this.$store.dispatch('app/openSnackbar', 
        //                       { show: true, color:'green', 
        //                         title: "Les données ont été actualisées",
        //                         text: null,
        //                         icon: 'thumbs-up' })
      }
    },
  },
}
</script>
